<template>
  <v-row class="matching" justify="center" align="center" style="overflow: hidden;">
    <v-col style="display: flex; align-items: center" cols="12" justify="center">
      <v-row>
        <!-- <v-spacer></v-spacer> -->
        
        <v-col class="pa-8" cols="12">
          <v-row class="img_container justify-center pb-9">
            <img
              src="@/assets/img/landing/matching/mail3d.png" 
              :width="$vuetify.breakpoint.mdAndUp ? '204px' : '160px'" 
              data-aos="fade-up-small"
              data-aos-once="true"
              data-aos-easing='ease-out-cubic'
              data-aos-duration="600"/>
          </v-row>
          <v-row class="title-lead justify-center pb-3"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing='ease-out-cubic'
            data-aos-duration="700"
            data-aos-delay='200'>
            서비스 소개서 요청이 완료되었습니다.
          </v-row>
          <v-row class="content-lead justify-center pb-3"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing='ease-out-cubic'
            data-aos-duration="700"
            data-aos-delay='300'>
            담당자가 확인 후 작성해주신 이메일로<br>서비스 소개서를 보내드립니다.
          </v-row>
          <v-row class="caption-lead justify-center pb-8"
            data-aos="fade-up-small"
            data-aos-once="true"
            data-aos-easing='ease-out-cubic'
            data-aos-duration="1100"
            data-aos-delay='800'>
            잠시 후 이전 화면으로 이동합니다.
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'LeadComplete',
    methods: {
      isMatching() {
        return ((process.env.VUE_APP_TARGET === 'campaign') || location.href.indexOf('superchart') > -1);
      }
    },
    mounted(){
      setTimeout( () => this.$router.push({
        path: this.isMatching() ? '/' : '/signup?utm_source=pipedrive&utm_medium=webform'
      }), 4000);
    }
  }
</script>

<style scoped lang="scss">
  * {
    font-family: 'Pretendard'
  }
  .matching {
    width: 100vw;
    background: #fbfbfb;
    height: 100%;
    min-height: 90vh;
  }

  .title-lead {
    text-align: center;
    color: #0F1633;
    font-weight: 800;
    font-size: 27px;
    line-height: 40px;
    word-break: keep-all;
    @media only screen and (max-width: 959px) {
      font-size: 24px;
      line-height: 36px;
    }
  }

  .content-lead {
    text-align: center;
    color: #21232E;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    @media only screen and (max-width: 959px) {
      font-size: 14px;
      line-height: 28px;
    }
  }

  .caption-lead {
    text-align: center;
    color: #757575;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    @media only screen and (max-width: 959px) {
      font-size: 13px;
      line-height: 28px;
    }
  }

  /*  Animation starts here */
  [data-aos='fade-up-small'] {
    transform: translate(0,48px);
    opacity: 0;
    transition-property: opacity transform;  
  }
  /*  Animation ends here */
  [data-aos='fade-up-small'].aos-animate {
    transform: translate(0,0);
    opacity: 1;
  }



</style>
